import { useLocation, useMatches, RemixBrowser } from "@remix-run/react";
import { useEffect, startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import * as Sentry from "@sentry/remix";

Sentry.init({
  dsn: "https://ea66922e95a35cc4112f0b0baf13e6c3@o4507742046715904.ingest.us.sentry.io/4507742049992704",
  tracesSampleRate: 1,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
  enabled: import.meta.env.PROD,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
